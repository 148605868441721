.site-secondary-color {
  color: #0094d9;
}

.site-secondary-background {
  background: #0094d9;
}

.site-primary-color {
  color: #2f3091;
}

.site-primary-background {
  background: #2f3091;
}

.light-background {
  background: white;
}

.site-logo {
  height: 60px;
}

.vision-card-item {
  background-color: #efeded;
  padding: 40px 60px;
}

.address-card-item {
  background-color: #2f3091;
  color: white;
  padding: 38px 60px;
}

.address-icon {
  color: white;
  font-size: 30px;
}

.social-media-info {
  font-size: 40px;
  padding: 8px;
}

.social-media-sticky {
  background-color: green;
  position: fixed;
  display: block;
  right: 0px;
  padding: 10px;
  padding-right: 20px;
  bottom: 100px;
  z-index: 11;
  animation: action 1s infinite alternate;
}

.social-media-sticky svg {
  font-size: 4cqb;
}

.social-media-sticky:hover {
  right: 0px;
  padding-right: 40px;
  transition: 0.5s ease-in-out;
}

.footer-site-name {
  text-decoration: underline;
}
